<template>
  <div>
    <b-card
      no-body
    >
      <b-modal
          v-model="createUserModal"
          id="modal-create-user"
          cancel-variant="outline-secondary"
          ok-title="Создать"
          cancel-title="Закрыть"
          centered
          title="Создание пользователя"
          @ok="createNewUser"
      >
        <b-form>
          <b-form-group>
            <label>Роль</label>
            <v-select
                v-model="newUser.role"
                label="name"
                key="id"
                :options="roles"
            />
          </b-form-group>
          <b-form-group>
            <label for="name">Имя:</label>
            <b-form-input
                v-model="newUser.name"
                id="name"
                type="text"
                placeholder="Имя"
            />
          </b-form-group>
          <b-form-group>
            <label for="email">Email:</label>
            <b-form-input
                v-model="newUser.email"
                id="email"
                type="email"
                placeholder="Email"
            />
          </b-form-group>
          <b-form-group>
            <label for="password">Пароль:</label>
            <b-form-input
                v-model="newUser.password"
                id="password"
                type="password"
                placeholder="Пароль"
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <b-modal
          v-model="deleteModal"
          id="modal-danger"
          ok-only
          ok-variant="danger"
          ok-title="Удалить"
          modal-class="modal-danger"
          centered
          title="Удаление пользователя"
          @ok="deleteUser"
      >
        Вы действительно хотите удалить пользователя?
      </b-modal>
      <div>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                    variant="primary"
                    @click="createUserModal = true"
                >
                  <span class="text-nowrap">Создать пользователя</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          :items="items"
          :fields="fields"
          striped
          responsive
          class="mb-0"
        >
          <!-- Column: Action -->
          <template #cell(actions)="row">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>
<!--                <b-dropdown-item>-->
<!--                  <feather-icon-->
<!--                      icon="Edit2Icon"-->
<!--                      class="mr-50"-->
<!--                  />-->
<!--                  <span>Редактировать</span>-->
<!--                </b-dropdown-item>-->
                <b-dropdown-item @click="openRemoveModal(row.item.id)">
                  <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                  />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>
      </div>
    </b-card>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="page"
              :total-rows="countItems"
              per-page="20"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BTable, BCard, BDropdown, BDropdownItem, BPagination, BRow, BCol, BButton, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BCard,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,

    vSelect,
  },
  data() {
    return {
      fields: [
        'email',
        { key: 'role', label: 'Роль' },
        { key: 'subscription', label: 'Подписка' },
        'country',
        'bot',
        'telegram',
        { key: 'date', label: 'Дата' },
        { key: 'actions', label: 'Действия' },
      ],
      newUser: {
        role: null,
        name: '',
        email: '',
        password: '',
      },
      createUserModal: false,
      deleteModal: false,
      removeId: '',
      page: 1,
    }
  },
  computed: {
    ...mapState({
      items: state => state.user.users,
      popupCreate: state => state.user.popupCreate,
      popupEdit: state => state.user.popupEdit,
      countItems: state => state.user.countUsers,
      usersData: state => state.user.usersData,
      roles: state => state.role.roles,
    }),
  },
  watch: {
    page() {
      this.getItems(this.page)
    },
  },
  mounted() {
    this.getRoles()
    this.getItems(1)
  },
  methods: {
    ...mapActions({
      getItems: 'user/getUsers',
      deleteItem: 'user/deleteUser',
      getRoles: 'role/getRoles',
      createUser: 'user/createUser',
    }),
    openRemoveModal(id) {
      this.removeId = id
      this.deleteModal = true
    },
    createNewUser() {
      this.createUser({
        role_id: this.newUser.role.id,
        email: this.newUser.email,
        name: this.newUser.name,
        password: this.newUser.password,
        subscription_id: 1,
      }).then(() => {
        this.createOrderModal = false

        this.$bvToast.toast('Пользователь успешно создан', {
          title: 'Сделка',
          variant: 'success',
          solid: true,
        })
      })
        .catch(() => {
          this.$bvToast.toast('Ошибка', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
          })
        })
    },
    deleteUser() {
      // eslint-disable-next-line no-return-assign
      this.deleteItem(this.removeId).then(() => this.deleteModal = false)
    },
  },
}
</script>

<style scoped>
table tbody tr > td:nth-child(7) {
  white-space: nowrap;
}
.mb-0 td[aria-colindex="7"] {
  white-space: nowrap;
}
</style>